const tica = document.querySelector('section[data-id="8cc1c36"]');
tica.id = 'tica';
let unaVez = 0; 

function activeHexa(titulo, hexa, hexai) {
    const titled = document.querySelector(titulo);
    const hexad = document.querySelector(hexa);
    const ihexad = document.querySelector(hexai);

    titled.addEventListener('mouseover', function () {
        hexad.style.transition = 'background-color .5s';
        ihexad.style.transition = 'color .5s';
        hexad.style.backgroundColor = 'white';
        ihexad.style.setProperty('--cold', '#4e52a6');
    });

    titled.addEventListener('mouseout', function () {
        hexad.style.backgroundColor = '#4e52a6';
        ihexad.style.setProperty('--cold', 'white');
    });
}

activeHexa('.titulo-design', '.design .hexagono', '.design .hexagono i');
activeHexa('.titulo-didactic', '.didactic .hexagono', '.didactic .hexagono i');
activeHexa('.titulo-audio', '.audiovisual .hexagono', '.audiovisual .hexagono i');
activeHexa('.titulo-web', '.web .hexagono', '.web .hexagono i');
activeHexa('.titulo-ovas', '.ovas .hexagono', '.ovas .hexagono i');
activeHexa('.titulo-capacitacion', '.capacitacion .hexagono', '.capacitacion .hexagono i');

window.addEventListener('scroll', function() {
    const y = window.scrollY;
    const tituloTecno = document.querySelector('.uniTecno .titulo');
    const hexaDiv = document.querySelector('.uniTecno .contenidos .parte2');
    const titulos = document.querySelectorAll('.tit-tica');
    const mail = document.querySelector('.uniTecno .mail');
    const anchoPantalla = document.body.clientWidth;

        if( anchoPantalla > 800) {
            if(y >= 3500 && y <= 4000) {
                tituloTecno.style.animation = 'appear-tica 1s forwards';
                hexaDiv.style.animation = 'appear-tica 1.5s forwards';

                if(mail) {
                    mail.style.animation = 'appear-tica 2s forwards'; 
                }
         
                let titdelay = 0;
         
                 titulos.forEach(titu => {
                     titu.style.animation = `move-tica 2s ease ${titdelay}s 1 normal forwards`;
                     titdelay += .4;
                 });
             }
        }

        if( anchoPantalla < 800) {

            if(y >= 4400 && y <= 5000) {
                unaVez++;
        
                if(unaVez == 1) {
                    const lista = document.querySelectorAll('.titulos-lista .orden .hexagono');
                    const iconosHexa = this.document.querySelectorAll('.tecnoMovil .centrar .hexagono i');
                    iconosHexa[6].style.opacity = '0';
                    let i = 0;
        
                    iconosHexa.forEach(icon => {
                        icon.style.setProperty('--cold', '#4e52a6');
                    });
        
                setInterval(() => {
                    if(lista[i-1]) {
                        lista[i-1].style.backgroundColor = '#4e52a6';
                        iconosHexa[i-1].style.opacity = '0';
                    }
        
                    if(i > 5) {
                        i = 0;
                    }
        
                    lista[i].style.backgroundColor = 'white';
                    iconosHexa[i].style.opacity = '1';
        
                    i++;
                }, 1500);
        
                }
         
            }
        }
    
  });

